<template>
	<main-container :showHeader="false">
		<el-card>
			<advanced-search-bar>
				<template>
					<form-item label="站点:">
						<el-select value="" size="mini" multiple @focus="triggerWebsite" :popper-append-to-body="false"  empty>
						</el-select>
						<small class="w-25 text-primary ml-1" style="cursor:pointer;"
							@click="triggerWebsite">已选择{{queryForm.sites.length}}个</small>
					</form-item>
					<form-item>
						<el-button type="primary" @click="searchEvent" icon="el-icon-search" size="mini">查询</el-button>
						<el-button type="primary" size="mini" @click="downloadTask">下载</el-button>
					</form-item>
				</template>
				<template #advanced>
					<form-item label="BM ID:">
						<el-input v-model="queryForm.bmId" size="mini"></el-input>
					</form-item>
					<form-item label="Account ID:">
						<el-input v-model="queryForm.accountId" size="mini"></el-input>
					</form-item>
					<form-item label="选择日期:">
						<el-date-picker v-model="queryForm.date" type="daterange" size="mini" :clearable="false"
							value-format="timestamp">
						</el-date-picker>
					</form-item>
				</template>
			</advanced-search-bar>
			<div class="d-flex flex-row flex-wrap">
				
			</div>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
			<el-table height="auto" :data="tableData">
				<el-table-column label="Day" prop="day" min-width="150">
				</el-table-column>
				<el-table-column label="项目" prop="project" min-width="150">
				</el-table-column>
				<el-table-column label="站点" prop="site.code" min-width="150">
				</el-table-column>
				<el-table-column label="渠道" prop="channel" min-width="150">
				</el-table-column>
				<el-table-column label="BM Name" prop="bmName" min-width="150">
				</el-table-column>
				<el-table-column label="BM ID" prop="bmId" min-width="150">
				</el-table-column>
				<el-table-column label="Account Name" prop="accountName" min-width="150">
				</el-table-column>
				<el-table-column label="Account ID" prop="accountId" min-width="150">
				</el-table-column>
				<el-table-column label="账户时区" prop="accountTimeZone" min-width="150">
				</el-table-column>
				<el-table-column label="花费" prop="cost" min-width="150">
					<template slot-scope="scope">
						<div v-if="scope.row.cost || scope.row.cost === 0">
							{{scope.row.cost}} <em>{{scope.row.currencyCode}}</em> 
						</div>
					</template>
				</el-table-column>
				<el-table-column label="余额" prop="balance" min-width="150">
					<template slot-scope="scope">
						<div v-if="scope.row.balance || scope.row.balance === 0">
						{{scope.row.balance}} <em>{{scope.row.currencyCode}}</em>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="余额更新时间" prop="updatedAt" min-width="200" align="center">
					<template slot-scope="scope">
						{{scope.row.updatedAt | formatDate('YYYY-MM-DD HH:mm:ss')}}
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
		</el-card>
		<el-dialog title="选择站点" :visible.sync="visible.site" :before-close="handleClose">
			<el-select v-model="sites" placeholder="请选择" size="mini" filterable multiple clearable class="w-100">
				<el-option v-for="item in websites" :label="item.key" :value="item.key"></el-option>
			</el-select>
			<div slot="footer" class="dialog-footer">
				<el-button @click="visible.site = false">取 消</el-button>
				<el-button type="primary" @click="updateWebsites">确 定</el-button>
			</div>
		</el-dialog>
	</main-container>
</template>

<script>
	import Moment from 'moment'
	import page from '@/common/mixins/page.js'
	import common from '@/common/mixins/common.js'
	import {
		mapActions
	} from 'vuex'
	export default {
		mixins: [page, common],
		inject: ['adminLayout'],
		data() {
			return {
				q: {
					startDate: Moment().add(-7, 'days').startOf('day').format('x'),
					endDate: Moment().add(-1, 'days').endOf('day').format('x')
				},
				queryForm: {
					sites: '',
					bmId: '',
					accountId: '',
					date: [
						Moment().add(-7, 'days').startOf('day').format('x'),
						Moment().add(-1, 'days').endOf('day').format('x')
					]
				},
				tableData: [],
				websites: [],
				sites: [],
				visible: {
					site: false
				}
			}
		},
		mounted() {
			this.getAllChannels()
				.then(data => {
					this.websites = Object.keys(data).map(key => {
						return {
							key,
							value: data[key]
						}
					})
				})
		},
		methods: {
			...mapActions([
				'fetchCostAndBalanceList',
				'exportCostAndBalanceList',
				'getAllChannels'
			]),
			searchEvent() {
				this.q = {
					...this.queryForm,
					startDate: Moment(Number(this.queryForm.date[0])).startOf('day').format('x'),
					endDate: Moment(Number(this.queryForm.date[1])).endOf('day').format('x')
				}
				this.page.current = 1
				console.log(this.q)
				this.getList()
			},
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				const _params = {
					...this.page,
					...this.q
				}
				const params = {}
				Object.keys(_params).forEach(key => {
					if (_params[key] !== '') {
						params[key] = _params[key]
					}
				})
				return this.fetchCostAndBalanceList({
					params,
					trigger
				})
			},
			handleList(content) {
				this.tableData = content
			},
			triggerWebsite() {
				this.sites = [...this.queryForm.sites]
				this.visible.site = true
			},
			updateWebsites() {
				this.queryForm.sites = [...this.sites]
				this.visible.site = false
			},
			downloadTask() {
				const startDate = Moment(Number(this.q.startDate)).format('YYYY-MM-DD')
				const endDate = Moment(Number(this.q.endDate)).format('YYYY-MM-DD')
				this.$store.commit('download-manager/addTask', {
					action: `exportCostAndBalanceList`,
					payload: {...this.q},
					title: `Cost_Balance(${startDate}~${endDate})`,
					dispatch: this.$store.dispatch,
					responseType: 'blob'
				})
			}
		}
	}
</script>

<style>
</style>
